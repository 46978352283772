import revive_payload_client_YTGlmQWWT5 from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_VeD7Ba96AP from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/apps/frontends/.nuxt/components.plugin.mjs";
import unhead_neSs9z3UJp from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_8FoqvPFTot from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ACZKItkFsL from "/opt/atlassian/pipelines/agent/build/packages/upa-i18n/dist/runtime/plugin.mjs";
import composition_N1rIkEnC9Q from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_dOTnCc6TUQ from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import shopware_plugin_txRT4f0fRY from "/opt/atlassian/pipelines/agent/build/apps/frontends/.nuxt/runtime/shopware.plugin.mjs";
import unocss_MzCDxu9LMj from "/opt/atlassian/pipelines/agent/build/apps/frontends/.nuxt/unocss.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ssr_plugin_PNV9fRmkNu from "/opt/atlassian/pipelines/agent/build/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import google_recaptcha_rYK9Kziv68 from "/opt/atlassian/pipelines/agent/build/apps/frontends/plugins/google-recaptcha.ts";
import sentry_client_shVUlIjFLk from "/opt/atlassian/pipelines/agent/build/apps/frontends/plugins/sentry.client.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  router_VeD7Ba96AP,
  components_plugin_KR1HBZs4kY,
  unhead_neSs9z3UJp,
  prefetch_client_8FoqvPFTot,
  plugin_ACZKItkFsL,
  composition_N1rIkEnC9Q,
  i18n_dOTnCc6TUQ,
  shopware_plugin_txRT4f0fRY,
  unocss_MzCDxu9LMj,
  chunk_reload_client_SeG0EjL5Ec,
  ssr_plugin_PNV9fRmkNu,
  google_recaptcha_rYK9Kziv68,
  sentry_client_shVUlIjFLk
]